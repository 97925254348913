<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="用户名称:">
                    <el-select v-model="filters.customerId" clearable filterable remote placeholder="请输入关键词"
                        :loading="userLoading" :remote-method="getCustomerInfoList">
                        <el-option v-for="item in customerList" :key="item.memberId" :label="item.trueName"
                            :value="item.memberId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="聊天来源:">
                    <el-select v-model="filters.sourceType" clearable>
                        <el-option v-for="item in sourceTypeList" :key="item.key" :label="item.value" :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题分类:">
                    <el-select v-model="filters.contentType" clearable placeholder="请选择">
                        <el-option v-for="item in contentTypeList" :key="item.key" :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属知识库:">
                    <el-select v-model="filters.knowledgeId" clearable placeholder="请选择">
                        <el-option v-for="item in knowledgeList" :key="item.knowledgeId" :label="item.knowledgeName"
                            :value="item.knowledgeId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审查状态:">
                    <el-select v-model="filters.checkStatus" clearable placeholder="请选择">
                        <el-option v-for="item in checkStatusList" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保底消息:">
                    <el-select v-model="filters.isBaseBottom" clearable placeholder="请选择">
                        <el-option v-for="item in isBaseBottomList" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户问题:">
                    <el-input v-model="filters.content" @input="(e) => (filters.content = utils.validForbid(e))"
                    placeholder="请输入问题" clearable @clear='clearContent'></el-input>
                </el-form-item>
                <el-form-item label="对话模型:">
                    <el-select v-model="filters.modelType" clearable placeholder="请选择">
                        <el-option v-for="item in modelTypeList" :key="item.key" :label="item.value" :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>

            </el-form>
        </el-col>
        <el-table :data="messageList" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
            @selection-change="selsChange" style="width: 100%" border>
            <!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
            <!-- <el-table-column type="index" width="80" align="center" :index='indexMethod'> </el-table-column> -->
            <!-- <el-table-column prop="conversationNo" label="会话编号" width="150" align="center"></el-table-column> -->
            <el-table-column label="聊天来源" width="100" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.sourceTypeIdName }}</span>
                    <span style="margin-left: 10px" v-if="scope.row.sourceTypeName">({{ scope.row.sourceTypeName
                        }})</span>
                </template>
            </el-table-column>
            <el-table-column prop="customerName" label="用户名称" width="140" align="center"></el-table-column>
            <el-table-column label="用户问题" width align="center">
                <template slot-scope="scope">
                    <el-tag color="rgb(255, 136, 64)" size="mini" effect="dark" type="warning" disable-transitions
                        v-if="scope.row.isShared">题</el-tag>
                    <span style="margin-left: 10px">{{ scope.row.messageContent }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="messageContent" label="用户问题" width="150" align="center" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="回复" width="" align="center">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="light" placement="top-start">
                        <div slot="content" class="tooltip-content">
                            <!-- {{ scope.row.answerContent }} -->
                            <mavon-editor previewBackground="#ffffff" boxShadowStyle="none"
                                v-model="scope.row.answerContent" :editable='false' :toolbarsFlag='false'
                                :subfield='false' :defaultOpen='"preview"' />
                        </div>
                        <span class="ellipsis-text">{{ scope.row.answerContent }}</span>
                    </el-tooltip>
                    <el-button type="text" size="small" v-if="scope.row.knowledgeId"
                        @click.native="handleMessageKnowledgeList(scope.row)">[参考知识点]</el-button>
                    <el-button type="text" size="small" v-if="scope.row.isQuestionContent"
                        @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button>
                    <!-- <el-button  type="text" size="small"  @click.native="handleViewTitleAnalysisList(scope.row)">[参考题目解析]</el-button> -->
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="发送时间" width="200" align="center"></el-table-column>
            <el-table-column prop="contentTypeName" label="问题分类" width="100" align="center"></el-table-column>
            <el-table-column prop="knowledgeName" label="所属知识库" width="100" align="center"></el-table-column>
            <el-table-column prop="modelTypeName" label="对话模型" width="100" align="center"></el-table-column>
            <el-table-column prop="checkStatusName" label="审查状态" width="100" align="center"></el-table-column>
            <!-- <el-table-column prop="isEnable" label="启用标识" width="100" align="center">
				<template slot-scope="scope">
					<el-tag
						:type="scope.row.isEnable == true ? 'success' : 'danger'"
						disable-transitions
					>{{ scope.row.isEnable == true ? "启用" : "禁用" }}
					</el-tag>
				</template>
			</el-table-column> -->
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                        @click.native="handleChatConversation(scope.row)">查看会话</el-button>
                    <el-button type="text" size="small" @click.native="handleImportChat(scope.row)">导入到数据</el-button>
                    <el-button type="text" size="small" v-if="!(scope.row.isFeedback || scope.row.checkStatus)"
                        @click.native="handleFeedback(scope.row)">错误反馈</el-button>
                    <el-button type="text" size="small" v-if="!(scope.row.isFeedback || scope.row.checkStatus)"
                        @click.native="checkMessageFunction(scope.row)">审查</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="pageBar" align="center">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog title="聊天会话" :visible.sync="chatConversationDialogVisible" v-model="chatConversationDialogVisible"
            :close-on-click-modal="false">
            <div class="chat-conversation">
                <div :class="item.isAnswer ? 'left' : 'right'" v-for="item in chatConversationList">
                    <div :class="item.isAnswer ? 'chat-conversation-item-left' : 'chat-conversation-item'">
                        <mavon-editor v-if='item.isAnswer' previewBackground="#ffffff" boxShadowStyle="none"
                            v-model="item.messageContent" :editable='false' :toolbarsFlag='false' :subfield='false'
                            :defaultOpen='"preview"' />
                        <span v-else>{{ item.messageContent }} </span>
                        <!-- <span >{{ item.messageContent }} </span> -->
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="问题反馈" :visible.sync="feedbackDialogFormVisible" v-model="feedbackDialogFormVisible"
            :close-on-click-modal="false" :before-close="closeFeedback">
            <el-form :model="feedbackForm" ref="feedbackForm" :rules="feedbackFormRules" label-position="top"
                style="margin-left: 20px; padding-bottom: 0px;">
                <el-form-item label="问题分类" prop="feedbackErrorType">
                    <el-radio-group v-model="feedbackForm.feedbackErrorType" size="small">
                        <el-radio v-for="item in errorTypeList" :label="item.key" border>{{ item.value }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="反馈内容" prop="feedbackContent">
                    <el-input type="textarea" minlength="" v-model="feedbackForm.feedbackContent" rows="5"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="closeFeedback">取 消</el-button>
                <el-button type="primary" @click.native="saveFeedback" :loading="feedbackLoading">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看知识点" :visible.sync="viewKnowledgekDialogVisible" v-model="viewKnowledgekDialogVisible"
            :close-on-click-modal="false">
            <div style="overflow-y: scroll;max-height: 600px;">
                <el-collapse accordion v-model="choseMessageKnowledge" v-for="item in messageKnowledgeList">
                    <el-collapse-item :name="item.knowledgeId">
                        <template slot="title">
                            <el-tag size="small" effect="plain" type="info"
                                style="margin-left: 10px;margin-right: 10px;">知识库</el-tag>
                            <span>{{ item.knowledgeName }}</span>
                        </template>
                        <el-collapse v-for="oneContent in item.contents" class="bookContent" v-model="choseBookContent">
                            <el-collapse-item :name="oneContent.contentId">
                                <template #title>
                                    <div class="custom_wrap">
                                        <div class="custom_title">{{ oneContent.bookContentTitle }}</div>
                                        <span style="margin-right: 10px; ">匹配分数: {{ oneContent.difyScore }}</span>
                                    </div>
                                </template>
                                <div>{{ oneContent.bookContent }}</div>
                                <div class="pic-keyword">
                                    <span v-if="oneContent.imageUrls">
                                        <img class="pic-img" v-for="(item, index) in oneContent.imageUrls" :key="index"
                                            :src="item" @click="handlePictureCardPreview(item)" />
                                    </span>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-dialog>

        <el-dialog title="查看题目解析" :visible.sync="viewTitleAnalysisDialogVisible"
            v-model="viewTitleAnalysisDialogVisible" :close-on-click-modal="false">
            <p
                style="border-left: 4px solid rgb(50, 115, 245);padding-left: 20px;font-size: 16px;color: rgb(50, 115, 245);">
                单选题
            </p>
            <p style="line-height: 25px;">{{ questionDetailInfo.questionTitle }}</p>
            <p v-for="item in questionDetailInfo.answer">{{ item.answer }}</p>
            <p style="color: rgb(50, 115, 245); ">正确答案：<span style="color: red;">{{ questionDetailInfo.correctAnswer
                    }}</span>
            </p>
            <p style="line-height: 25px;"><span style="color: rgb(50, 115, 245); margin-right: 20px;">解析:</span>{{
            questionDetailInfo.answerAnalysis
        }}</p>
        </el-dialog>

        <el-dialog title="导出excel" :visible.sync="exportExcelDialogVisible" v-model="exportExcelDialogVisible"
            :close-on-click-modal="false">
            <div style="margin-top: 20px">
                <el-radio v-model="exportExcelRadio" :label="false" border size="medium">导出新增聊天记录</el-radio>
                <span>距离上一次导出新增 <span style="color: rgb(201, 21, 30);">{{ newCount }}</span> 条数据</span>
            </div>
            <div style="margin-top: 20px">
                <el-radio v-model="exportExcelRadio" :label="true" border size="medium">导出所有聊天记录</el-radio>
                <span><span style="color: rgb(201, 21, 30);">{{ totalCount }}</span> 条</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="exportExcelDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click.native="exportMessageExcel" :loading="exportLoading">导 出</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogPreview" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
    getMessageListPage,
    getFeedbackDataPage,
    addFeedback,
    getByNamespace,
    getKnowledgeDataPageList,
    getCustomerList,
    getConversationDetail,
    getMessageKnowledgeList,
    getQuestionDetailsByQuestionId,
    exportMessageExcel,
    getMessageCountForExport,
    checkMessageNoFeedback,
    importChat
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
export default {
    components: { Toolbar },
    data() {
        return {
            host: process.env.VUE_APP_BASE_URL,
            utils: '',
            messageList: [],
            customerList: [],
            contentTypeList: [],
            knowledgeList: [],
            modelTypeList: [],
            sourceTypeList: [],
            messageKnowledgeList: [],
            checkStatusList: [
                {
                    id: 0,
                    name: '未审查'
                },
                {
                    id: 1,
                    name: '已查无异议'
                },
                {
                    id: 2,
                    name: '已反馈'
                }
            ],
            isBaseBottomList: [
                {
                    id: true,
                    name: '是'
                },
                {
                    id: false,
                    name: '否'
                },
            ],
            errorTypeList: [],
            listLoading: false,
            userLoading: false,
            currentRow: null,
            //列表选中列
            sels: [],
            //增删改查按钮
            buttonList: [],
            filters: {
                customerId: null,
                sourceType: null,
                contentType: null,
                knowledgeId: null,
                modelType: null,
                checkStatus: null,
                isBaseBottom: null,
                content: null
            },
            //关于分页的obj
            pages: {
                pageSize: 20, //默认每页条数
                pageArr: [10, 20, 30, 40], //默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            //新增界面是否显示
            addFormVisible: false,
            addLoading: false,
            chatConversationDialogVisible: false,//查看聊天会话详情
            chatConversationList: [],
            feedbackDialogFormVisible: false,
            feedbackLoading: false,
            feedbackFormRules: {
                feedbackErrorType: [
                    { required: true, message: '请选择问题反馈', trigger: 'radio' }
                ],
                feedbackContent: [
                    { required: true, message: '请输入反馈内容', trigger: 'blur' }
                ]
            },
            formLabelWidth: '120px',
            feedbackForm: {
                messageId: 0,
                feedbackErrorType: 1,
                feedbackContent: ''
            },
            viewKnowledgekDialogVisible: false,
            activeNames: ['1'],
            activeNames2: ['1'],
            choseMessageKnowledge: [],
            choseBookContent: [],
            viewTitleAnalysisDialogVisible: false,//查看题目解析
            questionDetailInfo: {},
            exportExcelDialogVisible: false,//导出excel
            exportExcelRadio: false, //是否权限
            newCount: 0,//新增数量
            totalCount: 0, //总共数量
            exportLoading: false,
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("Token")}`,
            },
            checkLoading: false,
            dialogPreview: false,
            dialogImageUrl: ''
        }
    },
    methods: {
        // 查询
        getListData() {
            this.pages.pageIndex = 1
            this._getListData();
        },
        // 清空筛选框内容，重新获取数据
        clearContent() {
            this._getListData();
        },
        //当前行发生变化时
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        //选项发生变化时
        selsChange: function (sels) {
            this.sels = sels;
        },
        handleChatConversation: function (val) {
            this.chatConversationList = [];
            this.chatConversationDialogVisible = true;
            let params = {
                conversationId: val.conversationId,
                customerId: val.customerId
            };
            getConversationDetail(params).then((res) => {
                if (res.data.success) {
                    this.chatConversationList = res.data.response;
                }
            });
        },
        handleImportChat: function (val) {
            this.$confirm('确认导入吗？', '提示', {}).then(() => {
                let params = [{
                    dataQuestion: val.messageContent,
                    dataType: 1
                }];
                importChat(params).then((res) => {
                    if (res.data.success) {
                        this.$message({
                            message: '导入成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: '导入失败',
                            type: 'error'
                        });
                    }
                });
            });
        },
        //显示反馈弹框
        handleFeedback: function (val) {
            var _this = this;
            _this.feedbackForm.messageId = val.messageId;
            _this.feedbackForm.feedbackErrorType = 1;
            _this.feedbackForm.feedbackContent = '';
            _this.feedbackDialogFormVisible = true;
        },
        closeFeedback: function () {
            var _this = this;
            this.feedbackDialogFormVisible = false;
            _this.feedbackForm.messageId = 1;
            _this.feedbackForm.feedbackErrorType = 1;
            _this.feedbackForm.feedbackContent = '';
            this._getListData();
        },
        saveFeedback: function (val) {
            this.$refs.feedbackForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.feedbackLoading = true;
                        let params = Object.assign({}, this.feedbackForm);
                        addFeedback(params).then((res) => {
                            if (util.isEmt.format(res)) {
                                this.feedbackLoading = false;
                                return;
                            }
                            if (res.data.success) {
                                this.feedbackLoading = false;
                                this.feedbackDialogFormVisible = false;
                                this.$message({
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.closeFeedback();
                                // this._getListData(); 
                                // this.getDataPermissions();
                            }
                            else {
                                this.feedbackLoading = false;
                                this.$message({
                                    message: res.data.message,
                                    type: 'error'
                                });
                            }
                        });
                    });
                }
            });
        },
        //获取消息来源类型
        getSourceType() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.MessageSourceType ',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.sourceTypeList = [...data.response];
                }
            });
        },
        //获取学员问题分类
        getCustomerInfoList(query) {
            var _this = this;
            if (query !== '') {
                _this.userLoading = true;
                var params = {
                    customerName: query,
                };
                setTimeout(() => {

                    getCustomerList(params).then((res) => {
                        this.userLoading = false;
                        var data = res.data;
                        // var resData = [data.response];
                        if (data.success) {
                            _this.customerList = data.response;
                        }
                    });

                }, 200);
            } else {
                _this.customerList = [];
            }
        },
        //获取学员问题分类
        getContentTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.ContentType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.contentTypeList = data.response;
                }
            });
        },
        //获取对话模型列表
        getModelTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.ModelType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.modelTypeList = data.response;
                }
            });
        },
        //问题反馈类型
        getErrorTypeList() {
            var _this = this;
            var params = {
                namespace: 'BizService.OmsAicsService.FeedbackErrorType',
            };
            getByNamespace(params).then((res) => {
                var data = res.data;
                // var resData = [data.response];
                if (data.success) {
                    _this.errorTypeList = data.response;
                }
            });
        },
        //获取所属知识库
        getKnowledgeList() {
            var _this = this;
            var params = {
                knowledgeName: '',
                pageIndex: 1,
                pageSize: 999
            };
            getKnowledgeDataPageList(params).then((res) => {
                var data = res.data;
                if (data.success) {
                    _this.knowledgeList = [{
                        knowledgeId: 0,
                        knowledgeName: "没有匹配知识库"
                    },
                    ...data.response.data];
                }
            });
        },
        _getListData() {
            this.listLoading = true;
            var params = {
                customerId: this.filters.customerId,
                sourceType: this.filters.sourceType,
                contentType: this.filters.contentType,
                knowledgeId: this.filters.knowledgeId,
                checkStatus: this.filters.checkStatus,
                modelType: this.filters.modelType,
                isBaseBottom: this.filters.isBaseBottom,
                content: this.filters.content,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            getMessageListPage(params).then((res) => {
                this.listLoading = false;
                this.messageList = res.data.response.data;

                this.pages.dataCount = res.data.response.dataCount;
                this.pages.pageIndex = res.data.response.pageIndex;
                if (res.data.response.pageSize > 0) {
                    this.pages.pageSize = res.data.response.pageSize;
                }
            });

        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
            // if(this.filters.name != '' || this.filters.selectc != false){
            //   this.pages.pageIndex = 1
            // }else{
            this.pages.pageIndex = val;
            // }      
            this._getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this._getListData();
        },
        toFeedbackIssue() {

        },
        //查看知识点弹框
        handleMessageKnowledgeList(val) {
            this.viewKnowledgekDialogVisible = true;
            this.messageKnowledgeList = [];
            this.choseMessageKnowledge = [];
            this.choseBookContent = [];
            let params = {
                answerMessageId: val.answerMessageId
            };
            getMessageKnowledgeList(params).then((res) => {
                if (res.data.success) {
                    this.messageKnowledgeList = res.data.response;
                    res.data.response.forEach((item) => {
                        this.choseMessageKnowledge.push(item.knowledgeId);
                        item.contents.forEach((oneContent) => {
                            this.choseBookContent.push(oneContent.contentId);
                        });
                        // console.log(item.knowledgeId);
                    });
                }
            });
        },
        handleChange(val) {
            console.log(val);
        },
        handleViewTitleAnalysisList(val) { //查看题目解析
            this.viewTitleAnalysisDialogVisible = true;
            let params = {
                questionId: val.questionId
            };
            getQuestionDetailsByQuestionId(params).then((res) => {
                if (res.data.success) {
                    this.questionDetailInfo = res.data.response;
                }
            });
        },
        handleExportExcel() {
            this.exportExcelDialogVisible = true;
            this.exportExcelRadio = false;
            getMessageCountForExport().then((res) => {
                if (res.data.success) {
                    this.newCount = res.data.response.newCount;
                    this.totalCount = res.data.response.totalCount;
                }
            });
        },
        exportMessageExcel() {
            this.exportLoading = true;
            function createObjectURL(object) { return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object); }
			var xhr = new XMLHttpRequest();
			var formData = new FormData();
			xhr.open("GET", `/omswebapi/message/exportMessageExcel?isAll=${this.exportExcelRadio}`, true);
			xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem("Token")}`);
			xhr.responseType = 'blob';
			xhr.onload = function (e) {
				if (this.status == 200) {
					var blob = this.response;
					var filename = `聊天记录导出.xlsx`;
					if (window.navigator.msSaveOrOpenBlob) {
						navigator.msSaveBlob(blob, filename);
					} else {
						var a = document.createElement('a');
						var url = createObjectURL(blob);
						a.href = url;
						a.download = filename;
						document.body.appendChild(a);
						a.click();
						window.URL.revokeObjectURL(url);
					}

				}
			};
			xhr.send(formData);
            this.exportLoading = false;
        },
        checkMessageFunction(val) {
            this.$confirm('确认审查吗？', '提示', {}).then(() => {
                this.checkLoading = true;

                let params = {
                    messageId: val.messageId
                };
                checkMessageNoFeedback(params).then((res) => {
                    if (util.isEmt.format(res)) {
                        this.checkLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.checkLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        val.checkStatus = 1;
                        val.checkStatusName = '已查无异议';
                        val.isFeedback = true;
                        // this._getListData();
                    } else {
                        this.checkLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        },
        handlePictureCardPreview(url) {
            this.dialogImageUrl = url;
            this.dialogPreview = true;
        },
    },
    mounted() {
		this.utils = util

        this.getSourceType();
        this.getContentTypeList();
        this.getModelTypeList();
        this.getKnowledgeList();
        this.getErrorTypeList();
        this._getListData();
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
};
</script>
<style scoped>
.rolesTop {
    width: 100%;
    display: flex;
}

.rolesInp {
    width: 300px;
    margin-right: 20px;
}

.pageBar {
    margin-top: 15px;
}

.ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep .el-dialog__body {
    padding: 0px 20px 30px
}

::v-deep .el-collapse {
    border: none;
}

::v-deep .el-collapse-item__wrap {
    border: none;
}

::v-deep .el-collapse-item__header {
    border-bottom: none;
    font-weight: bold;
    font-size: 16px;
}

::v-deep .el-collapse-item__content {
    padding: 0px;
}

::v-deep .bookContent .el-collapse-item {
    margin-bottom: 15px;
}

::v-deep .bookContent .el-collapse-item__header {
    background-color: rgb(242, 243, 245);
    border-bottom: null;
    border-radius: 3px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 14px;
}

::v-deep .bookContent .el-collapse-item__wrap {

    border: 1px solid rgb(64, 158, 255);

    border-radius: 3px;
    margin-top: 15px;
}

::v-deep .bookContent .el-collapse-item__content {
    padding: 10px 15px;
}

.el-collapse-item__content .bookContentTitle {
    font-weight: bold;
}


::v-deep .el-collapse-item__arrow {
    display: none;
}

::v-deep .el-collapse-item__arrow.is-active {
    display: none;
}

::v-deep .v-note-wrapper {
    min-height: 0;
    min-width: 0
}

::v-deep .v-show-content {
    padding: 15px 25px 8px 25px;
}

::v-deep .markdown-body {
    font-size: 14px;
}

::v-deep .markdown-body img {
    width: 100%;
}

.chat-conversation {
    background-color: #F2F3F5;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 600px;
    overflow-y: auto;
}

.chat-conversation .right {
    display: flex;
    flex-direction: row-reverse;
}


.chat-conversation .left {
    display: flex;
    flex-direction: row;
}

.chat-conversation .chat-conversation-item {
    margin-left: 8px;
    background-color: #CEDBF8;
    height: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px 10px 0 10px;
    text-align: right;
}

.chat-conversation .chat-conversation-item-left {
    margin-right: 8px;
    background-color: #FFFFFF;
    height: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 10px 10px 10px 0;
}

.tooltip-content {
    width: 500px;
    max-height: 400px;
    overflow-y: auto;
}

.pic-img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    cursor: pointer;

}

.custom_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
</style>